<template>
  <div>
    <v-card id="invoice-list">
      <v-card-text class="d-flex align-center pb-5">
        <v-row>
          <v-col
            cols="12"
            sm="3"
            lg="2"
          >
            <!-- create invoice -->
            <v-btn
              color="primary"
              class="me-3 invoice-button"
              :to="{ name: 'invoice-form' }"
            >
              <v-icon
                size="18"
                class="me-1"
              >
                {{ icons.mdiPlus }}
              </v-icon>
              <span>Create invoice</span>
            </v-btn>
          </v-col>

          <v-spacer></v-spacer>

          <v-col
            cols="12"
            sm="3"
            class="pr-sm-0"
          >
            <div class="d-flex align-center">
              <!-- Date range -->
              <v-menu
                v-model="isDateMenuOpen"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :append-icon="icons.mdiCalendar"
                    :value="dateRangeText"
                    readonly
                    dense
                    outlined
                    hide-details
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="dateRange"
                  color="primary"
                  :first-day-of-week="1"
                  range
                >
                  <v-spacer></v-spacer>
                  <div class="w-full text-right">
                    <v-btn
                      color="primary"
                      block
                      class="mb-2"
                      @click="dateRangeChange()"
                    >
                      Set dates
                    </v-btn>
                    <span
                      v-if="firstRecord"
                      class="first-record-button caption primary--text"
                      @click="dateRange = [firstRecord, `${yearNow}-${monthNow}-${dayNow}`]; dateRangeChange()"
                    >
                      From the very beginning
                    </span>
                  </div>
                </v-date-picker>
              </v-menu>
            </div>
          </v-col>

          <v-col
            cols="12"
            sm="3"
            lg="2"
            class="pr-sm-0"
          >
            <!-- filter status -->
            <v-select
              v-model="statusFilter"
              :items="statusOptions"
              single-line
              outlined
              dense
              hide-details
              clearable
              placeholder="Select Status"
              class="mr-0 mr-sm-3"
            ></v-select>
          </v-col>

          <v-col
            cols="12"
            sm="3"
            lg="3"
            class="d-flex pl-sm-0"
          >
            <!-- search invoice -->
            <v-tooltip
              :left="$vuetify.breakpoint.smAndUp"
              :top="$vuetify.breakpoint.xsOnly"
              :nudge-bottom="$vuetify.breakpoint.smAndUp ? 20 : 0"
              :nudge-top="$vuetify.breakpoint.xsOnly ? 5 : 0"
              :nudge-right="$vuetify.breakpoint.xsOnly ? 100 : 0"
              max-width="250"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-avatar
                  size="30"
                  class="mr-3 my-auto hover-pointer d-none d-sm-block"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon size="25">
                    {{ icons.mdiAlertCircleOutline }}
                  </v-icon>
                </v-avatar>
              </template>
              <h3 class="my-2">
                How to use the search bar
              </h3>
              <p class="mb-1">
                You can search using:
              </p>
              <ul class="mb-2">
                <li>
                  Invoice No.
                </li>
                <li>
                  Customer name
                </li>
              </ul>
            </v-tooltip>
            <v-text-field
              v-model="searchText"
              :append-icon="icons.mdiMagnify"
              single-line
              dense
              outlined
              hide-details
              placeholder="Search Invoice"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-text class="pt-5">
        <v-row>
          <v-col
            cols="4"
            sm="2"
          >
            <div class="d-flex align-center">
              <v-select
                v-model="tablePagination.per"
                :items="listLengthOptions"
                single-line
                outlined
                dense
                hide-details
                class="invoice-list-row-selection"
                @input="listLengthChange()"
              ></v-select>
              <div class="pl-3">
                Rows
              </div>
            </div>
          </v-col>

          <v-spacer></v-spacer>

          <v-col
            sm="10"
            cols="4"
            class="d-flex justify-end"
          >
            <v-pagination
              v-model="tablePagination.current_page"
              total-visible="6"
              :length="tablePagination.total_page"
              @input="fetchInvoices"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-text>

      <!-- table -->
      <v-data-table
        :headers="tableHeaders"
        :items="invoices"
        :options.sync="options"
        :items-per-page="100"
        :loading="loading"
        mobile-breakpoint="0"
        hide-default-footer
        class="text-no-wrap fixed-action d-none d-sm-block"
      >
        <!-- Invoice Number -->
        <template #[`item.serial_number`]="{item}">
          <router-link
            class="font-weight-medium text-decoration-none"
            :to="{ name: 'invoice-view', params: { id: item.id } }"
          >
            {{ item.invoice_number }}
          </router-link>
        </template>

        <!-- Status -->
        <template #[`item.status`]="{item}">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-avatar
                size="30"
                :color="invoiceStatus(item.status).variant"
                :class="`v-avatar-light-bg ${invoiceStatus(item.status).variant}--text`"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon
                  size="18"
                  :color="invoiceStatus(item.status).variant"
                >
                  {{ invoiceStatus(item.status).icon }}
                </v-icon>
              </v-avatar>
            </template>
            <span>{{ item.status }}</span>
          </v-tooltip>
        </template>

        <!-- Notes -->
        <template #[`item.note`]="{item}">
          <div
            class="text-truncate py-1"
            style="width: 300px; max-height: 30px;"
            v-html="item.note"
          ></div>
        </template>

        <!-- Actions -->
        <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                class="me-2"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item :to="{ name: 'receipt-form', params: { invoice_id: item.id } }">
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiPlus }}
                  </v-icon>
                  <span>Create Receipt</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item @click="invoice = item; isReminderDialogVisible = true">
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiSend }}
                  </v-icon>
                  <span>Send Mail</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item :to="{ name: 'invoice-view', params: { id: item.id } }">
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiEyeOutline }}
                  </v-icon>
                  <span>View</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item :to="{ name: 'invoice-form', params: { id: item.id } }">
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiPencilOutline }}
                  </v-icon>
                  <span>Edit</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item @click="invoice = item; isDuplicateDialogVisible = true">
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiContentCopy }}
                  </v-icon>
                  <span>Duplicate</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item @click="invoice = item; isDownloadDialogVisible = true">
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiDownload }}
                  </v-icon>
                  <span>Download</span>
                </v-list-item-title>
              </v-list-item>

              <v-divider></v-divider>

              <v-list-item @click="invoice = item; isVoidDialogVisible = true">
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>Void</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>

      <!-- table view for mobile -->
      <v-data-table
        :headers="tableSmHeaders"
        :items="invoices"
        :options.sync="options"
        :items-per-page="100"
        :loading="loading"
        :expanded.sync="expanded"
        single-expand
        show-expand
        mobile-breakpoint="0"
        hide-default-footer
        class="text-no-wrap fixed-action d-block d-sm-none"
      >
        <!-- Invoice Number -->
        <template #[`item.serial_number`]="{item}">
          <router-link
            class="font-weight-medium text-decoration-none"
            :to="{ name: 'invoice-view', params: { id: item.id } }"
          >
            {{ item.invoice_number }}
          </router-link>
        </template>

        <!-- Status -->
        <template #[`item.status`]="{item}">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-avatar
                size="30"
                :color="invoiceStatus(item.status).variant"
                :class="`v-avatar-light-bg ${invoiceStatus(item.status).variant}--text`"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon
                  size="18"
                  :color="invoiceStatus(item.status).variant"
                >
                  {{ invoiceStatus(item.status).icon }}
                </v-icon>
              </v-avatar>
            </template>
            <span>{{ item.status }}</span>
          </v-tooltip>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td
            class="table-expand"
            :colspan="headers.length"
          >
            <p class="mt-2">
              Customer Name: {{ item.customer_name }}
            </p>
            <p>Invoice Date: {{ item.invoice_date }}</p>
            <p>Due Date: {{ item.due_date }}</p>
            <p>Notes:</p>
            <div class="d-flex">
              <v-sheet width="32vh">
                <p
                  class="text-wrap"
                  v-html="item.note"
                ></p>
              </v-sheet>
              <v-spacer></v-spacer>
              <div class="fixed-expand-action">
                <v-menu
                  bottom
                  left
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item :to="{ name: 'receipt-form', params: { invoice_id: item.id } }">
                      <v-list-item-title>
                        <v-icon
                          size="16"
                          class="me-2"
                        >
                          {{ icons.mdiPlus }}
                        </v-icon>
                        <span class="caption">Create Receipt</span>
                      </v-list-item-title>
                    </v-list-item>

                    <v-list-item @click="invoice = item; isReminderDialogVisible = true">
                      <v-list-item-title>
                        <v-icon
                          size="16"
                          class="me-2"
                        >
                          {{ icons.mdiSend }}
                        </v-icon>
                        <span class="caption">Send Mail</span>
                      </v-list-item-title>
                    </v-list-item>

                    <v-list-item :to="{ name: 'invoice-view', params: { id: item.id } }">
                      <v-list-item-title>
                        <v-icon
                          size="16"
                          class="me-2"
                        >
                          {{ icons.mdiEyeOutline }}
                        </v-icon>
                        <span class="caption">View</span>
                      </v-list-item-title>
                    </v-list-item>

                    <v-list-item :to="{ name: 'invoice-form', params: { id: item.id } }">
                      <v-list-item-title>
                        <v-icon
                          size="16"
                          class="me-2"
                        >
                          {{ icons.mdiPencilOutline }}
                        </v-icon>
                        <span class="caption">Edit</span>
                      </v-list-item-title>
                    </v-list-item>

                    <v-list-item @click="invoice = item; isDuplicateDialogVisible = true">
                      <v-list-item-title>
                        <v-icon
                          size="16"
                          class="me-2"
                        >
                          {{ icons.mdiContentCopy }}
                        </v-icon>
                        <span class="caption">Duplicate</span>
                      </v-list-item-title>
                    </v-list-item>

                    <v-list-item @click="invoice = item; isDownloadDialogVisible = true">
                      <v-list-item-title>
                        <v-icon
                          size="16"
                          class="me-2"
                        >
                          {{ icons.mdiDownload }}
                        </v-icon>
                        <span class="caption">Download</span>
                      </v-list-item-title>
                    </v-list-item>

                    <v-divider></v-divider>

                    <v-list-item @click="invoice = item; isVoidDialogVisible = true">
                      <v-list-item-title>
                        <v-icon
                          size="16"
                          class="me-2"
                        >
                          {{ icons.mdiDeleteOutline }}
                        </v-icon>
                        <span class="caption">Void</span>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </div>
          </td>
        </template>
      </v-data-table>

      <!-- Reminder dialog -->
      <v-dialog
        v-model="isReminderDialogVisible"
        width="500"
      >
        <v-card>
          <v-card-title class="d-flex align-center mv-4">
            Send reminder email for {{ invoice.invoice_number }}?
            <v-spacer></v-spacer>
            <v-btn
              icon
              small
              @click="isReminderDialogVisible = false"
            >
              <v-icon size="22">
                {{ icons.mdiClose }}
              </v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text>
            Send reminder email to the customer regarding payment on this invoice which will due soon. This invoice will be attached to the mail.
          </v-card-text>

          <v-card-actions>
            <v-btn
              color="primary"
              block
              :loading="reminderLoading"
              :disabled="reminderLoading"
              class="mt-3"
              @click="remindInvoice()"
            >
              Send mail
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Duplicate dialog -->
      <v-dialog
        v-model="isDuplicateDialogVisible"
        width="500"
      >
        <v-card>
          <v-card-title class="d-flex align-center mv-4">
            Duplicate {{ invoice.invoice_number }}?
            <v-spacer></v-spacer>
            <v-btn
              icon
              small
              @click="isDuplicateDialogVisible = false"
            >
              <v-icon size="22">
                {{ icons.mdiClose }}
              </v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text>
            This invoice will be duplicated. It copy all data present in this current invoice including line items.
          </v-card-text>

          <v-card-actions>
            <v-btn
              color="primary"
              block
              :loading="duplicateLoading"
              :disabled="duplicateLoading"
              class="mt-3"
              @click="duplicateInvoice()"
            >
              Make another copy
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Download dialog -->
      <v-dialog
        v-model="isDownloadDialogVisible"
        width="500"
      >
        <v-card>
          <v-card-title class="d-flex align-center mv-4">
            Download {{ invoice.invoice_number }}?
            <v-spacer></v-spacer>
            <v-btn
              icon
              small
              @click="isDownloadDialogVisible = false"
            >
              <v-icon size="22">
                {{ icons.mdiClose }}
              </v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text>
            Export this invoice as PDF.
          </v-card-text>

          <v-card-actions>
            <v-btn
              color="primary"
              block
              :loading="downloadLoading"
              :disabled="downloadLoading"
              class="mt-3"
              @click="downloadInvoice()"
            >
              Download PDF
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Void dialog -->
      <v-dialog
        v-model="isVoidDialogVisible"
        width="500"
      >
        <v-card>
          <v-card-title class="d-flex align-center mv-4">
            Void {{ invoice.invoice_number }}?
            <v-spacer></v-spacer>
            <v-btn
              icon
              small
              @click="isVoidDialogVisible = false"
            >
              <v-icon size="22">
                {{ icons.mdiClose }}
              </v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text>
            This invoice will be voided. It won't be taken into any calculations.
          </v-card-text>

          <v-card-actions>
            <v-btn
              color="error"
              block
              :loading="voidLoading"
              :disabled="voidLoading"
              class="mt-3"
              @click="voidInvoice()"
            >
              Remove
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-card-text class="pt-5">
        <v-row>
          <v-col
            cols="4"
            sm="2"
          >
            <div class="d-flex align-center">
              <v-select
                v-model="tablePagination.per"
                :items="listLengthOptions"
                single-line
                outlined
                dense
                hide-details
                class="invoice-list-row-selection"
                @input="listLengthChange()"
              ></v-select>
              <div class="pl-3">
                Rows
              </div>
            </div>
          </v-col>

          <v-spacer></v-spacer>

          <v-col
            sm="10"
            cols="4"
            class="d-flex justify-end"
          >
            <v-pagination
              v-model="tablePagination.current_page"
              total-visible="6"
              :length="tablePagination.total_page"
              @input="fetchInvoices"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-text>

      <template v-if="invoices.length !== 0">
        <v-divider class="mb-4"></v-divider>
        <v-row>
          <v-col>
            <v-btn
              color="primary"
              :loading="printLoading"
              :disabled="printLoading"
              class="ml-5 mb-1"
              @click="printReport"
            >
              <span>Print as CSV</span>
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </v-card>
  </div>
</template>

<script>
import {
  mdiPlus,
  mdiCheckCircleOutline,
  mdiAlertCircleOutline,
  mdiContentCopy,
  mdiDeleteOutline,
  mdiPencilOutline,
  mdiCalendar,
  mdiPrinter,
  mdiMagnify,
  mdiClose,
  mdiDotsVertical,
  mdiEyeOutline,
  mdiDownload,
  mdiSend,
  mdiChartTimelineVariant,
} from '@mdi/js'
import {
  ref,
  inject,
  computed,
  watch,
} from '@vue/composition-api'
import { saveAs } from 'file-saver'

export default {
  setup() {
    // Initializers
    const store = inject('store')
    const snackbarService = inject('snackbarService')
    const utilsService = inject('utilsService')

    // Properties
    const [dayNow, monthNow, yearNow] = new Date().toLocaleDateString('en-SG').split('/')
    const invoices = ref([])
    const invoice = ref({})

    const searchText = ref('')
    const statusFilter = ref(null)
    const statusOptions = ref(['Outstanding', 'Paid', 'Partially Paid'])
    const dateRange = ref([`${yearNow}-${monthNow}-01`, `${yearNow}-${monthNow}-${dayNow}`])
    const isDateMenuOpen = ref(false)
    const firstRecord = ref(null)

    const isVoidDialogVisible = ref(false)
    const voidLoading = ref(false)
    const isDuplicateDialogVisible = ref(false)
    const duplicateLoading = ref(false)
    const isDownloadDialogVisible = ref(false)
    const downloadLoading = ref(false)
    const isReminderDialogVisible = ref(false)
    const reminderLoading = ref(false)
    const printLoading = ref(false)

    // Table Handlers
    const loading = ref(false)
    const expanded = ref([])
    const options = ref({
      sortBy: ['serial_number'],
      sortDesc: [true],
    })
    const listLengthOptions = ref([20, 50, 100])
    const tablePagination = ref({
      current_page: 1,
      per: 20,
      total_count: 0,
      total_page: 0,
    })
    const tableHeaders = computed(() => (
      [
        { text: 'INVOICE NO.', value: 'serial_number' },
        { text: 'STATUS', value: 'status', sortable: false },
        { text: 'CUSTOMER NAME', value: 'customer_name' },
        { text: 'INVOICE DATE', value: 'invoice_date' },
        { text: 'DUE DATE', value: 'due_date' },
        { text: 'TOTAL', value: 'total' },
        { text: 'NOTES', value: 'note', sortable: false },
        {
          text: 'ACTIONS',
          value: 'actions',
          align: 'center',
          sortable: false,
        },
      ]
    ))
    const tableSmHeaders = computed(() => (
      [
        { text: 'INVOICE NO.', value: 'serial_number' },
        { text: 'STATUS', value: 'status', sortable: false },
        { text: 'TOTAL', value: 'total' },
        { text: '', value: 'data-table-expand' },
      ]
    ))

    // Computed
    const dateRangeText = computed(() => utilsService.dateRangeText(dateRange.value))

    // Methods
    const fetchInvoices = () => {
      loading.value = true
      store
        .dispatch('invoiceStore/fetchInvoices', {
          page: tablePagination.value.current_page,
          per: tablePagination.value.per,
          sort_by: options.value.sortBy[0],
          sort_dir: options.value.sortDesc[0] ? 'desc' : 'asc',
          search_text: searchText.value,
          start_date: dateRange.value[0],
          end_date: dateRange.value[1] ? dateRange.value[1] : dateRange.value[0],
          status: statusFilter.value,
        })
        .then(response => {
          const { data, pagination, first } = response.data
          invoices.value = data
          tablePagination.value = pagination
          firstRecord.value = first
          loading.value = false
        })
        .catch(error => {
          loading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching invoices. Please refresh!')
        })
    }
    const listLengthChange = () => {
      tablePagination.value.current_page = 1
      fetchInvoices()
    }
    const dateRangeChange = () => {
      isDateMenuOpen.value = false
      tablePagination.value.current_page = 1
      fetchInvoices()
    }
    const duplicateInvoice = () => {
      duplicateLoading.value = true
      store
        .dispatch('invoiceStore/duplicateInvoice', { id: invoice.value.id })
        .then(response => {
          snackbarService.success(response.data.message)
          fetchInvoices()
          isDuplicateDialogVisible.value = false
          duplicateLoading.value = false
        })
        .catch(error => {
          duplicateLoading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while duplicating invoice. Please refresh!')
        })
    }
    const downloadInvoice = () => {
      downloadLoading.value = true
      store
        .dispatch('invoiceStore/downloadInvoice', { id: invoice.value.id })
        .then(response => {
          saveAs(response, `${invoice.value.invoice_number}.pdf`)
          isDownloadDialogVisible.value = false
          downloadLoading.value = false
        })
        .catch(error => {
          downloadLoading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while downloading invoice. Please refresh!')
        })
    }
    const printReport = () => {
      printLoading.value = true
      store
        .dispatch('invoiceStore/printReport', {
          search_text: searchText.value,
          start_date: dateRange.value[0],
          end_date: dateRange.value[1] ? dateRange.value[1] : dateRange.value[0],
          status: statusFilter.value,
        })
        .then(response => {
          saveAs(response, `invoice(${dateRangeText.value}).csv`)
          printLoading.value = false
        })
        .catch(error => {
          printLoading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while printing report. Please refresh!')
        })
    }
    const remindInvoice = () => {
      reminderLoading.value = true
      const invoiceIds = [invoice.value.id]
      store
        .dispatch('invoiceStore/remindInvoice', { invoice_ids: invoiceIds })
        .then(response => {
          snackbarService.success(response.data.message)
          isReminderDialogVisible.value = false
          reminderLoading.value = false
        })
        .catch(error => {
          reminderLoading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while sending email reminder. Please refresh!')
        })
    }
    const voidInvoice = () => {
      voidLoading.value = true
      store
        .dispatch('invoiceStore/voidInvoice', { id: invoice.value.id })
        .then(response => {
          snackbarService.success(response.data.message)
          fetchInvoices()
          isVoidDialogVisible.value = false
          voidLoading.value = false
        })
        .catch(error => {
          voidLoading.value = false
          snackbarService.error(error.data.message || 'Something went wrong while voiding invoice. Please refresh!')
        })
    }
    const invoiceStatus = status => {
      let variantAndIcon = {}
      switch (status) {
        case 'Fully Paid':
          variantAndIcon = { variant: 'success', icon: mdiCheckCircleOutline }
          break
        case 'Partially Paid':
          variantAndIcon = { variant: 'warning', icon: mdiChartTimelineVariant }
          break
        case 'Outstanding':
          variantAndIcon = { variant: 'error', icon: mdiAlertCircleOutline }
          break
        default:
          variantAndIcon = { variant: 'secondary', icon: mdiClose }
      }

      return variantAndIcon
    }

    // Watch
    let timer = null
    watch([searchText, statusFilter, options], () => {
      if (timer) {
        clearTimeout(timer)
        timer = null
      }
      timer = setTimeout(() => {
        fetchInvoices()
      }, 200)
    })

    return {
      // Computed
      dateRangeText,

      // Properties
      invoices,
      invoice,
      dayNow,
      monthNow,
      yearNow,

      searchText,
      statusFilter,
      statusOptions,
      dateRange,
      isDateMenuOpen,
      firstRecord,

      isVoidDialogVisible,
      voidLoading,
      isDuplicateDialogVisible,
      duplicateLoading,
      isDownloadDialogVisible,
      downloadLoading,
      isReminderDialogVisible,
      reminderLoading,
      printLoading,

      // Table Handlers
      loading,
      expanded,
      options,
      listLengthOptions,
      tablePagination,
      tableHeaders,
      tableSmHeaders,

      // Icons
      icons: {
        mdiPlus,
        mdiCheckCircleOutline,
        mdiAlertCircleOutline,
        mdiContentCopy,
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiCalendar,
        mdiPrinter,
        mdiMagnify,
        mdiClose,
        mdiDotsVertical,
        mdiEyeOutline,
        mdiDownload,
        mdiSend,
      },

      // Methods
      fetchInvoices,
      listLengthChange,
      dateRangeChange,
      duplicateInvoice,
      downloadInvoice,
      printReport,
      remindInvoice,
      voidInvoice,
      invoiceStatus,
    }
  },
}
</script>
